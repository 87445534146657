window.addEventListener('heyflow-screen-view', (event) => {
    // console.log('heyflow screen view:', event.detail);
});

window.addEventListener('heyflow-submit', (event) => {
    // console.log('heyflow submit:', event.detail);
});

// Prefill input field for href
function setFullUrl() {
    const fullurl = document.querySelector('[data-label="fullurl"]');
    if (fullurl) {
        fullurl.value = window.location.href;
    } else {
        setTimeout(setFullUrl, 1000);
    }
}

setFullUrl();